import React from "react";
import { cn } from "../../../utils/tailwind";

export function Container({ children, className }) {
  return (
    <div
      className={cn(
        "mx-auto w-full max-w-[1440px] px-6 sm:px-6 md:px-10 lg:px-[100px]",
        className,
      )}
    >
      {children}
    </div>
  );
}
