/**
 * Converts font names into a Google Fonts URL format
 * @param {string|string[]} fontNames - Single font name or array of font names (e.g., "Noto Sans" or ["Noto Sans", "Lato"])
 * @returns {string} Google Fonts URL with formatted font names (e.g., "https://fonts.googleapis.com/css2?family=Noto+Sans&family=Lato&display=swap")
 */
export function getGoogleFontsUrl(fontNames) {
  // If single font name is passed as string, convert to array
  const fonts = Array.isArray(fontNames) ? fontNames : [fontNames];

  // Convert font names to Google Fonts format. Replace spaces with plus signs
  const formattedFonts = fonts.map((font) => {
    return font.replace(/\s+/g, "+");
  });

  // Build the Google Fonts URL
  const baseUrl = "https://fonts.googleapis.com/css2?";
  const fontFamilies = formattedFonts.map((font) => `family=${font}`).join("&");
  const displayParam = "&display=swap";

  return `${baseUrl}${fontFamilies}${displayParam}`;
}
