import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { baseTheme } from "../../themes/shipinsure";
import LoadingButton from "@mui/lab/LoadingButton";
import { GradientBox } from "./shared/GradientBox";
import { Menu } from "./shared/Menu";
import { useOnboarding } from "./OnboardingProvider";

export function Coverage() {
  const { form } = useOnboarding();
  const {
    control,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const coverages = [
    {
      label: "Merchant Coverage",
      value: "full_coverage",
      helperText: "This is a paid plan that covers all orders placed on your store.",
      subText: "Best for customers!",
    },
    {
      label: "Consumer Coverage",
      value: "in",
      helperText: "This plan allows for consumers to opt to pay for select orders. Only orders the consumers opt to pay for are covered.",
    },
  ];

  return (
    <GradientBox>
      <Box display="flex" padding="14px" height="100%">
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Menu step={3} />
        </Box>
        <Box
          bgcolor="#fff"
          width="100%"
          borderRadius="24px"
          sx={{ padding: { xs: "30px", md: "40px 69px" } }}
          display="flex"
          flexDirection="column"
        >
          <Typography variant="h3" color={baseTheme.colors.textLight}>
            Choose your coverage
          </Typography>
          <Grid
            container
            columnSpacing="60px"
            rowSpacing="20px"
            direction="row"
          >
            <Grid item xs={12}>
              <Controller
                name="coverage"
                control={control}
                rules={{ required: "Coverage required" }}
                render={({ field }) => (
                  <FormControl error={!!errors.coverage} sx={{ width: "100%" }}>
                    <RadioGroup {...field}>
                      {coverages.map((coverage, i) => (
                        <FormControlLabel
                          key={i}
                          sx={{
                            border: `1px solid ${
                              watch("coverage") === coverage.value
                                ? baseTheme.colors.primary
                                : "#E1E0E4"
                            }`,
                            padding: "20px",
                            mb: "20px",
                            borderRadius: "8px",
                            "& .MuiTypography-root": {
                              display: "inline-block",
                              width: "100%",
                            },
                          }}
                          value={coverage.value}
                          control={<Radio />}
                          label={
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box>
                                <Typography
                                  fontSize="20px"
                                  fontWeight={700}
                                  fontFamily="Montserrat, sans-serif"
                                  lineHeight="32px"
                                >
                                  {coverage.label}
                                </Typography>
                                {coverage.subText && (
                                  <Typography
                                    fontSize="13px"
                                    lineHeight="20px"
                                    letterSpacing=".25px"
                                    color={baseTheme.colors.primary}
                                  >
                                    {coverage.subText}
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                width="240px"
                                sx={{ display: { xs: "none", md: "block" } }}
                              >
                                <Typography
                                  fontSize="11px"
                                  lineHeight="16px"
                                  letterSpacing=".5px"
                                  fontWeight={600}
                                >
                                  {coverage.helperText}
                                </Typography>
                              </Box>
                            </Box>
                          }
                        />
                      ))}
                    </RadioGroup>
                    {errors.coverage && (
                      <FormHelperText>{errors.coverage.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            flexDirection="column"
            width="50%"
            sx={{ margin: { xs: "50px auto", md: "auto auto 0" } }}
          >
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Next
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </GradientBox>
  );
}
