export function ShipInsureLogo({ color = "#fff", width = 169, height = 37 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.6907 13.1626C58.2017 12.5997 57.5458 12.1457 56.7307 11.7967C55.9118 11.4477 54.9818 11.2751 53.937 11.2751C52.403 11.2751 51.2803 11.5678 50.58 12.1569C49.876 12.7461 49.5277 13.5416 49.5277 14.551C49.5277 15.0801 49.6204 15.5154 49.8093 15.8531C49.9983 16.1909 50.2873 16.4873 50.6801 16.735C51.0728 16.9826 51.573 17.1965 52.1881 17.3804C52.7994 17.5643 53.522 17.7444 54.3556 17.9245C55.2856 18.1234 56.123 18.3448 56.8678 18.5962C57.6125 18.8439 58.2424 19.1553 58.7686 19.5268C59.291 19.8983 59.6912 20.3486 59.9691 20.8777C60.2469 21.4068 60.384 22.0673 60.384 22.8628C60.384 23.6583 60.2284 24.375 59.9172 24.9717C59.6059 25.5683 59.1724 26.0637 58.6166 26.4614C58.0609 26.8592 57.3976 27.1519 56.6306 27.3433C55.8637 27.5346 55.0226 27.6284 54.1074 27.6284C51.41 27.6284 49.0831 26.7766 47.123 25.073L47.979 23.6583C48.2902 23.9885 48.6607 24.3037 49.0942 24.6002C49.5277 24.8966 50.0094 25.1593 50.5393 25.3807C51.0691 25.6058 51.636 25.7785 52.2437 25.9023C52.8476 26.0261 53.4849 26.0899 54.1556 26.0899C55.545 26.0899 56.6269 25.8385 57.4013 25.3319C58.1757 24.8291 58.5648 24.0636 58.5648 23.0354C58.5648 22.4875 58.4536 22.0297 58.235 21.6582C58.0127 21.2867 57.6829 20.964 57.242 20.6901C56.8011 20.4162 56.2527 20.1797 55.6006 19.9846C54.9485 19.7857 54.1889 19.5869 53.3219 19.388C52.4067 19.1741 51.6064 18.9489 50.9209 18.7163C50.2354 18.4836 49.6537 18.1947 49.1794 17.8495C48.7052 17.5005 48.3495 17.0839 48.1123 16.5961C47.8752 16.1083 47.7566 15.5079 47.7566 14.7987C47.7566 13.9881 47.9086 13.2639 48.2087 12.626C48.5125 11.9881 48.9386 11.459 49.4944 11.0387C50.0502 10.6184 50.7023 10.2957 51.4544 10.0705C52.2066 9.84539 53.0403 9.73657 53.9555 9.73657C55.1152 9.73657 56.149 9.91294 57.0567 10.2694C57.9645 10.6259 58.7834 11.125 59.5207 11.7704L58.687 13.1588L58.6907 13.1626Z"
        fill={color}
      />
      <path
        d="M73.7856 27.4821H72.1182V20.2586C72.1182 18.7876 71.87 17.6844 71.3698 16.9564C70.8696 16.2284 70.1396 15.8644 69.1763 15.8644C68.702 15.8644 68.2315 15.9582 67.7683 16.1496C67.3014 16.341 66.8642 16.5999 66.4567 16.9301C66.0491 17.2603 65.6934 17.6506 65.3895 18.0972C65.0857 18.5437 64.8634 19.0315 64.7152 19.5606V27.4784H63.0479V9.36511H64.7152V17.4817C65.2228 16.5061 65.9231 15.7368 66.8235 15.174C67.7201 14.6111 68.6946 14.3297 69.7395 14.3297C70.4731 14.3297 71.0956 14.4647 71.6032 14.7387C72.1108 15.0126 72.5258 15.3916 72.8519 15.8794C73.1779 16.3673 73.4151 16.9564 73.5633 17.6431C73.7115 18.3298 73.7856 19.0878 73.7856 19.9134V27.4821Z"
        fill={color}
      />
      <path
        d="M77.3389 11.8455V9.36511H79.0062V11.8455H77.3389ZM77.3389 27.4821V14.5511H79.0062V27.4821H77.3389Z"
        fill={color}
      />
      <path
        d="M89.1996 27.7298C88.1214 27.7298 87.1692 27.4521 86.3466 26.8968C85.5203 26.3414 84.8571 25.6434 84.3495 24.7991V32.7657H82.6821V14.5511H84.1753V17.0577C84.6978 16.2472 85.3758 15.5905 86.2095 15.0839C87.0432 14.5811 87.951 14.3259 88.9291 14.3259C89.811 14.3259 90.6187 14.5136 91.3561 14.885C92.0897 15.2565 92.727 15.7519 93.2679 16.3748C93.8052 16.9939 94.2276 17.7107 94.5314 18.5212C94.8353 19.3317 94.9835 20.1686 94.9835 21.0279C94.9835 21.9397 94.8427 22.799 94.5685 23.6096C94.2906 24.4201 93.8941 25.1331 93.3791 25.7448C92.8641 26.3564 92.2527 26.8405 91.5413 27.197C90.8299 27.5535 90.0518 27.7298 89.1996 27.7298ZM88.7328 26.2401C89.4182 26.2401 90.0444 26.09 90.6076 25.7935C91.1708 25.4971 91.6525 25.1031 92.0526 24.6153C92.4528 24.1274 92.7603 23.5721 92.9715 22.9529C93.1827 22.3337 93.2902 21.6921 93.2902 21.0279C93.2902 20.3337 93.1679 19.6732 92.9234 19.0428C92.6788 18.4124 92.3416 17.8608 91.9192 17.3805C91.4931 16.9001 90.9929 16.5211 90.4112 16.2397C89.8295 15.9583 89.207 15.8194 88.5364 15.8194C88.1103 15.8194 87.6657 15.9057 87.2025 16.0783C86.7356 16.251 86.3021 16.4836 85.902 16.7726C85.5018 17.0615 85.1572 17.403 84.8719 17.7895C84.5866 18.1797 84.4088 18.5888 84.3458 19.0165V22.9867C84.5422 23.4482 84.7904 23.8798 85.0942 24.2775C85.3981 24.6753 85.7389 25.0168 86.1243 25.3057C86.5096 25.5947 86.9209 25.8236 87.3618 25.9887C87.8027 26.1538 88.2585 26.2363 88.7328 26.2363V26.2401Z"
        fill={color}
      />
      <path d="M97.7769 27.4822V9.86047H101.16V27.4822H97.7769Z" fill={color} />
      <path
        d="M116.4 27.4821H113.117V20.1872C113.117 19.144 112.935 18.3823 112.576 17.9057C112.216 17.4254 111.72 17.1852 111.083 17.1852C110.757 17.1852 110.419 17.2528 110.078 17.3841C109.734 17.5155 109.412 17.7031 109.111 17.9432C108.808 18.1834 108.537 18.4686 108.289 18.7988C108.044 19.129 107.863 19.493 107.752 19.8908V27.4858H104.469V14.4797H107.433V16.8888C107.907 16.0632 108.593 15.4178 109.493 14.9525C110.39 14.491 111.405 14.2583 112.531 14.2583C113.332 14.2583 113.984 14.4084 114.491 14.7048C114.999 15.0013 115.388 15.3916 115.666 15.8719C115.944 16.3522 116.136 16.8963 116.24 17.5117C116.348 18.1234 116.4 18.7463 116.4 19.3729V27.4896V27.4821Z"
        fill={color}
      />
      <path
        d="M124.121 27.7298C123.043 27.7298 121.991 27.5572 120.96 27.2082C119.93 26.8592 119.049 26.3639 118.315 25.7185L119.541 23.6321C120.327 24.1949 121.09 24.6227 121.831 24.9117C122.576 25.2006 123.313 25.347 124.051 25.347C124.703 25.347 125.218 25.2231 125.596 24.9755C125.97 24.7278 126.159 24.3713 126.159 23.9098C126.159 23.4482 125.937 23.1067 125.496 22.8928C125.055 22.6789 124.336 22.4313 123.339 22.1498C122.506 21.9172 121.794 21.6958 121.209 21.4781C120.62 21.2643 120.145 21.0203 119.786 20.7464C119.427 20.4725 119.163 20.161 119 19.8045C118.837 19.448 118.756 19.024 118.756 18.5249C118.756 17.8645 118.882 17.2678 119.138 16.7387C119.389 16.2096 119.745 15.7594 120.205 15.3879C120.66 15.0164 121.198 14.7312 121.809 14.5323C122.42 14.3334 123.087 14.2358 123.806 14.2358C124.769 14.2358 125.674 14.3784 126.515 14.6561C127.356 14.9376 128.126 15.3916 128.83 16.022L127.508 18.0334C126.855 17.538 126.222 17.174 125.607 16.9414C124.995 16.7087 124.384 16.5924 123.78 16.5924C123.224 16.5924 122.757 16.7087 122.383 16.9414C122.009 17.174 121.82 17.5455 121.82 18.0596C121.82 18.2923 121.865 18.4799 121.953 18.63C122.042 18.7801 122.187 18.9114 122.383 19.0278C122.58 19.1441 122.835 19.2567 123.154 19.3617C123.473 19.4706 123.869 19.5831 124.343 19.6957C125.225 19.9284 125.981 20.1573 126.611 20.3899C127.241 20.6226 127.756 20.8852 128.156 21.1854C128.556 21.4819 128.849 21.8271 129.038 22.2136C129.227 22.6039 129.319 23.0692 129.319 23.6171C129.319 24.8929 128.853 25.8948 127.923 26.6341C126.993 27.3695 125.725 27.7373 124.125 27.7373L124.121 27.7298Z"
        fill={color}
      />
      <path
        d="M135.367 27.7297C134.044 27.7297 133.04 27.2982 132.354 26.4389C131.669 25.5796 131.324 24.3037 131.324 22.6151V14.476H134.607V21.8946C134.607 23.8985 135.318 24.8966 136.738 24.8966C137.375 24.8966 137.99 24.7015 138.586 24.315C139.183 23.9247 139.668 23.3356 140.046 22.54V14.476H143.329V23.6583C143.329 24.0073 143.392 24.2549 143.514 24.4013C143.637 24.5514 143.837 24.6339 144.115 24.6489V27.4783C143.789 27.5459 143.514 27.5871 143.292 27.6022C143.07 27.6172 142.87 27.6284 142.692 27.6284C142.103 27.6284 141.625 27.4933 141.258 27.2194C140.891 26.9455 140.672 26.5702 140.609 26.0899L140.535 25.0467C139.965 25.9398 139.227 26.6115 138.331 27.058C137.43 27.5046 136.445 27.7297 135.367 27.7297Z"
        fill={color}
      />
      <path
        d="M154.237 17.3542C153.24 17.3692 152.351 17.5643 151.566 17.9358C150.78 18.3073 150.217 18.8665 149.876 19.6095V27.4784H146.593V14.4723H149.606V17.2529C149.835 16.8063 150.102 16.4048 150.413 16.0483C150.725 15.6919 151.058 15.3842 151.417 15.1177C151.777 14.8551 152.14 14.6487 152.507 14.5098C152.874 14.371 153.229 14.2997 153.574 14.2997H153.978C154.067 14.2997 154.152 14.3072 154.233 14.3259V17.3542H154.237Z"
        fill={color}
      />
      <path
        d="M161.392 27.7298C160.362 27.7298 159.432 27.5535 158.598 27.197C157.765 26.8405 157.053 26.3564 156.468 25.7448C155.879 25.1331 155.427 24.4201 155.108 23.6096C154.789 22.7991 154.63 21.9472 154.63 21.0541C154.63 20.1611 154.786 19.253 155.097 18.4349C155.408 17.6169 155.856 16.8964 156.445 16.2772C157.035 15.6581 157.75 15.1665 158.591 14.8025C159.432 14.4385 160.377 14.2546 161.422 14.2546C162.466 14.2546 163.404 14.4385 164.226 14.8025C165.053 15.1665 165.753 15.6543 166.335 16.266C166.913 16.8776 167.357 17.5906 167.657 18.4011C167.961 19.2117 168.109 20.056 168.109 20.9341C168.109 21.148 168.106 21.3543 168.098 21.5532C168.091 21.7521 168.069 21.9172 168.035 22.0486H158.109C158.157 22.5626 158.28 23.0167 158.476 23.4145C158.672 23.8122 158.924 24.15 159.235 24.4314C159.547 24.7128 159.899 24.9267 160.288 25.0768C160.681 25.2269 161.088 25.302 161.514 25.302C162.166 25.302 162.785 25.1406 163.363 24.8179C163.941 24.4952 164.338 24.0711 164.552 23.5383L167.372 24.3338C166.898 25.3282 166.142 26.1425 165.105 26.7767C164.067 27.4146 162.83 27.7336 161.392 27.7336V27.7298ZM164.723 19.8871C164.641 18.9115 164.286 18.131 163.656 17.5418C163.026 16.9564 162.263 16.66 161.366 16.66C160.925 16.66 160.514 16.7388 160.128 16.8964C159.743 17.054 159.406 17.2716 159.113 17.5531C158.82 17.8345 158.58 18.1722 158.391 18.57C158.202 18.9678 158.094 19.4068 158.061 19.8871H164.727H164.723Z"
        fill={color}
      />
      <path
        d="M31.9835 7.89418L16.5142 0.58808C16.2956 0.48301 16.0399 0.48301 15.8213 0.58808L0.474268 7.83414C0.185261 7.96923 0 8.26567 0 8.58463V28.5179C0 28.6792 0.0926305 28.8256 0.237134 28.8931L15.8213 36.2517C16.0399 36.3568 16.2956 36.3568 16.5142 36.2517L32.0983 28.8931C32.2428 28.8256 32.3355 28.6792 32.3355 28.5179V16.7838L36.5075 14.8138C36.741 14.7049 36.8151 14.4085 36.6669 14.1946L32.5615 8.37825C32.4133 8.16811 32.2132 7.99925 31.9835 7.89042V7.89418ZM16.5142 1.6763L29.2305 7.68028C29.5454 7.83038 29.5454 8.28444 29.2305 8.43078L16.5142 14.4348C16.2956 14.5398 16.0399 14.5398 15.8213 14.4348L3.10497 8.43454C2.79003 8.28444 2.79003 7.83038 3.10497 7.68404L15.8213 1.6763C16.0399 1.57123 16.2956 1.57123 16.5142 1.6763ZM23.0946 25.1894C22.8056 25.3245 22.6204 25.6209 22.6204 25.9399V32.2816L17.9073 34.5068C17.6368 34.6344 17.3256 34.4355 17.3256 34.1316V17.3279L20.5936 21.9548C20.7085 22.1161 20.9197 22.1724 21.1012 22.0898L31.1868 17.3279V27.97C31.1868 28.1314 31.0942 28.2777 30.9497 28.3452L27.2445 30.0939V23.876C27.2445 23.5721 26.9332 23.3732 26.6628 23.5008"
        fill={color}
      />
    </svg>
  );
}
