import React from "react";
import { useTenantTheme } from "../../../../themes/TenantThemeProvider";
import { LineItem } from "../../types";
import { Item } from "./Item";
import { OverviewProps } from "./PackageOverview";

export function ItemsOverview(props: OverviewProps) {
  const { siTheme } = useTenantTheme();

  return (
    <>
      <div className="mb-6 flex items-center justify-between">
        <p className="font-semibold text-gray-900">
          Your order{" "}
          <span className="font-normal text-gray-600">
            ({props.items.length} item{props.items.length > 1 ? "s" : ""})
          </span>
        </p>
        <p className="font-secondary text-xs text-gray-600">
          Order No:{" "}
          <a
            target="_blank"
            className="font-bold underline"
            href={props.orderStatusUrl}
            style={{ color: siTheme.primaryColor }}
          >
            {props.orderNumber}
          </a>
        </p>
      </div>
      {props.items.map((item, i) => (
        <Item {...item} key={i} />
      ))}
    </>
  );
}
