import { Box, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createTranslator } from "next-intl";
import content from "../../../content/claims.json";
import { getOrderLookup } from "../../../api/orderLookup";
import { useClaims } from "../ClaimsProvider";

export function OrderLookup() {
  const { onOrderLookup, onOrderLookupError, locale } = useClaims();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const t = createTranslator({
    messages: content[locale],
    locale,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: searchParams.get("email") ?? "",
      orderNumber:
        (searchParams.get("order_number") ||
          searchParams.get("ship_insure_id")) ??
        "",
    },
  });

  const handleFormSubmit = async (values) => {
    const { orderNumber, email } = values;
    const isShipInsureId = orderNumber.startsWith("SI");

    try {
      const response = await getOrderLookup(
        isShipInsureId ? { shipInsureId: orderNumber } : { orderNumber, email }
      );

      if (response.data.has_claims) {
        navigate(`/claims/${response.data.shipInsureID}`);
      } else {
        onOrderLookup(response.data);
      }
    } catch (error) {
      let message = error.message;

      if (error.response?.status === 404) {
        message = error.response.data.Message;
      }

      onOrderLookupError(message);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h3">{t("lookup.title")}</Typography>
      {t.rich("lookup.body", {
        text: (chunk) => <Typography paragraph>{chunk}</Typography>,
      })}
      <Box sx={{ my: 4 }}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              error={errors.email ? true : false}
              helperText={errors.email?.message}
              label={t("lookup.form.email")}
              variant="outlined"
            />
          )}
        />
      </Box>
      <Controller
        name="orderNumber"
        control={control}
        rules={{
          required: "Required",
        }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            error={errors.orderNumber ? true : false}
            helperText={errors.orderNumber?.message}
            label={t("lookup.form.order")}
            variant="outlined"
          />
        )}
      />
      <LoadingButton
        loading={isSubmitting}
        variant="contained"
        fullWidth
        type="submit"
        sx={{ mt: "46px" }}
      >
        {t("lookup.submit")}
      </LoadingButton>
    </form>
  );
}
