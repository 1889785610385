import { createContext, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createTranslator } from "next-intl";
import { useForm } from "react-hook-form";
import { createUpload } from "../../api/upload";
import { createClaim } from "../../api/claim";
import { MainLayout } from "../../layouts/MainLayout";
import { Card } from "../../shared/Card";
import { useTenantTheme } from "../../themes/TenantThemeProvider";
import { ProgressBar } from "./components/ProgressBar";
import { HappyBox } from "../../svgs/HappyBox";
import { Alert, Box, Snackbar } from "@mui/material";
import { Loader } from "../../shared/Loader";
import { usePreFetchOrder, useScreenAnalytics } from "./hooks";
import { ERROR, track } from "../../helpers/heap";
import content from "../../content/claims.json";

const ClaimsContext = createContext();

/*
ClaimsProvider is the top-level orchestrator for claims/returns flow;
it takes care of pre-fetching order if it exists, tracking analytics,
submitting a claim, and most importantly..holds the state for the
main claims form; we need to hold state in one top-level place in
order to go back and force and allow the user to edit after review;
the Claims component is in charge of the claims "routing" by renering
the step it needs; every component pulls actions it needs from the claims provider
*/

const ClaimsProvider = ({ children }) => {
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [claimMessage, setClaimMessage] = useState({
    isActive: false,
    level: null,
    message: null,
  });
  const [, setSearchParams] = useSearchParams();
  const { siTheme, isAppLoading, isThemeLoading, locale } = useTenantTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [order, setOrder] = useState();
  const claimsForm = useForm();

  const t = createTranslator({
    messages: content[locale],
  });

  const onOrderLookup = (data) => {
    setOrder(data);
    setActiveStep(1);
  };

  const onOrderLookupError = (message) => {
    setClaimMessage({
      isActive: true,
      message,
      level: "error",
    });
  };

  const onErrorClose = () => {
    setClaimMessage({
      ...claimMessage,
      isActive: false,
    });
  };

  // fetch order if query param and track analytics
  usePreFetchOrder(onOrderLookup);
  useScreenAnalytics(activeStep);

  const onSelectAllToggle = () => {
    claimsForm.reset();
    setIsSelectAll((prevState) => !prevState);
  };

  const onClaimSubmit = async (values) => {
    setIsSubmitting(true);

    const images = values
      .filter((d) => d.images.length)
      .map((d) => d.images)
      .flatMap((imgs) => imgs);
    let uploadedImages = [];

    if (images?.length) {
      try {
        uploadedImages = await Promise.all(
          images.map((image) => createUpload(image))
        );
      } catch (error) {
        const message = "Error uploading images";

        track(ERROR, {
          message,
        });

        setClaimMessage({
          isActive: true,
          message,
          level: "error",
        });

        setIsSubmitting(false);
        return;
      }
    }

    try {
      const payload = {
        // TODO once backfill is complete we can
        // remove these fields at the top level;
        // uses first item to support old model
        preferred_resolution: values[0].resolution,
        reason: values[0].reason,
        additional_info: values[0].details,

        from_page: "multi-tenant",
        email_address: order.email,
        shipInsureID: order.shipInsureID,
        items: values.map((item) => ({
          id: item.itemId,
          claim_quantity: item.lineItem.quantity,
          reason: item.reason,
          preferred_resolution: item.resolution,
          additional_info: item.details,
        })),
        // images at the claim level, not item level
        images: uploadedImages.map((image) => ({
          key: image.data.file_name,
        })),
      };

      await createClaim(payload);

      // set for thank you page tracking link
      setSearchParams({
        shipInsureId: order.shipInsureID,
      });

      setActiveStep(3);
    } catch (error) {
      track(ERROR, {
        message: error.message,
      });

      setClaimMessage({
        isActive: true,
        message: error.message,
        level: "error",
      });
      setIsSubmitting(false);
    }
  };

  if (isAppLoading || isThemeLoading) {
    return <Loader />;
  }

  return (
    <ClaimsContext.Provider
      value={{
        locale,
        activeStep,
        setActiveStep,
        claimsForm,
        order,
        onOrderLookup,
        onOrderLookupError,
        onClaimSubmit,
        isSubmitting,
        setIsSubmitting,
        isSelectAll,
        onSelectAllToggle,
        setIsSelectAll,
      }}
    >
      <MainLayout helpText={t("helpText")}>
        <Card
          enableBrand={!siTheme.isPrimaryTheme}
          poweredByText={t("poweredByText")}
        >
          {activeStep === 3 && (
            <Box display="flex" justifyContent="center" mb="36px">
              <HappyBox />
            </Box>
          )}
          <ProgressBar width={getProgressBarWidth(activeStep)} />
          {children}
        </Card>
        <Snackbar
          open={claimMessage.isActive}
          autoHideDuration={3000}
          onClose={(_, reason) => {
            if (reason === "clickaway") return;
            onErrorClose();
          }}
        >
          <Alert
            onClose={onErrorClose}
            severity={claimMessage.level}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {claimMessage.message}
          </Alert>
        </Snackbar>
      </MainLayout>
    </ClaimsContext.Provider>
  );
};

function getProgressBarWidth(activeStep) {
  switch (activeStep) {
    case 1: {
      return "33.33%";
    }
    case 2: {
      return "66.66%";
    }
    case 3: {
      return "100%";
    }
    case 0:
    default: {
      return "0%";
    }
  }
}

export const useClaims = () => useContext(ClaimsContext);
export { ClaimsProvider };
