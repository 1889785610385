import React from "react";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import { format } from "date-fns";
import { cn } from "../../../utils/tailwind";
import { Shipment } from "../types";

type Props = {
  shipment: Shipment;
};

const steps = [
  "waiting_for_pickup",
  "in_transit",
  "out_for_delivery",
  "delivered",
];
const statuses = {
  delivered: "Delivered",
  waiting_for_pickup: "Ready to Ship",
  unknown_carrier: "Unknown Carrier",
  failure: "Failure",
  cancelled: "Cancelled",
  in_transit: "In Transit",
  return_to_sender: "Return to Sender",
  available_for_pickup: "Available for Pickup",
  out_for_delivery: "Out for Delivery",
  error: "Error",
  alert: "Alert",
};

export function EstimatedDeliveryCard({ shipment }: Props) {
  if (!steps.includes(shipment.status))
    return <>Your order is {statuses[shipment.status]}</>;

  return (
    <div className="flex flex-col justify-center gap-8 p-6 md:px-8 md:py-10">
      {/* Top half - Estimated Delivery */}
      {shipment.estimated_delivery_date && (
        <div className="flex flex-col justify-center gap-4">
          <h3 className="font-secondary text-center text-base font-normal">
            Estimated delivery
          </h3>
          <div className="space-y-1 text-center">
            <div className="text-xl font-normal uppercase text-black">
              {format(new Date(shipment.estimated_delivery_date), "LLL")}
            </div>
            <div className="font-secondary overflow-clip text-[84px] font-semibold leading-none text-gray-800">
              {format(new Date(shipment.estimated_delivery_date), "dd")}
            </div>
            <div className="text-2xl uppercase text-gray-400">
              {format(new Date(shipment.estimated_delivery_date), "cccc")}
            </div>
          </div>
        </div>
      )}

      {/* Bottom half - Status and Details */}
      <div className="flex flex-col">
        <p className="text-center text-2xl font-semibold text-gray-900">
          Your order is{" "}
          <span className="lowercase">{statuses[shipment.status]}</span>
        </p>
        <div className="flex flex-col gap-6">
          <ShippingStatus steps={steps} shipment={shipment} />
          {shipment.status === "out_for_delivery" && <DeliveryDetailsCard />}
        </div>
      </div>
    </div>
  );
}

function ShippingStatus({ steps, shipment }) {
  return (
    <div className="relative">
      {/* Progress Bar */}
      <div className="h-4">
        <ProgressBar steps={steps} shipment={shipment} />
      </div>

      {/* Steps */}
      <div className="mt-1 flex w-full justify-between">
        {steps.map((step) => (
          <span
            key={step}
            className={cn(
              "font-secondary mt-6 max-w-16 flex-1 text-center text-sm leading-[1.14] text-gray-600",
              step.status === shipment.status && "font-semibold text-gray-800",
            )}
          >
            {statuses[step]}
          </span>
        ))}
      </div>
    </div>
  );
}

function ProgressBar({ steps, shipment }) {
  return (
    <div className="relative">
      {/* Background bar */}
      <div className="absolute left-0 top-4 h-4 w-full rounded-full bg-gray-100"></div>

      {/* Progress fill */}
      <div
        className={`${cn(
          "absolute left-0 top-4 z-10 h-4 rounded-full bg-gray-300",
          {
            "w-full": shipment.status === "delivered",
            "w-1/3": shipment.status === "in_transit",
            "w-2/3": shipment.status === "out_for_delivery",
          },
        )} `}
      ></div>

      {/* Step indicators */}
      <div className="absolute left-0 top-4 flex w-full justify-between">
        {steps.map((step) => (
          <div
            key={step.name}
            className={`z-20 rounded-full ${
              step.status === "delivered"
                ? "size-4 bg-gray-600"
                : step.includes(shipment.status)
                  ? "-mt-1 size-6 border-[5px] border-gray-600 bg-white"
                  : "size-4 border-2 border-gray-200 bg-gray-200"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
}

function DeliveryDetailsCard() {
  return (
    <div className="font-secondary rounded-lg border border-gray-100 p-3">
      <div className="flex items-center justify-between">
        <div className="w-full space-y-1">
          <div className="text-base text-gray-800">Out for Delivery</div>
          <div className="grid grid-cols-12 justify-between text-sm text-gray-600">
            <div className="col-span-7 flex min-w-0 items-center gap-px">
              <LocationOnOutlinedIcon
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
              <span className="truncate tracking-tighter">
                San Diego, CA, USA
              </span>
            </div>
            <div className="col-span-5 flex min-w-0 items-center gap-px">
              <ScheduleOutlinedIcon
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
              <span className="truncate tracking-tighter">
                Sep 01, 11:20 am
              </span>
            </div>
          </div>
        </div>
        <div className="text-gray-400">
          <KeyboardArrowRightOutlinedIcon
            sx={{
              width: "32px",
              height: "32px",
            }}
          />
        </div>
      </div>
    </div>
  );
}
