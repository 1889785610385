import { useEffect, useState } from "react";
import { VIEW_SCREEN, track } from "../../helpers/heap";
import { useTenantTheme } from "../../themes/TenantThemeProvider";
import { useSearchParams } from "react-router-dom";
import { getOrderLookup } from "../../api/orderLookup";

export function usePreFetchOrder(onOrderLookupSuccess) {
  const { setIsAppLoading } = useTenantTheme();
  const [hasFetchedOrder, setHasFetchedOrder] = useState(false);
  const [searchParams] = useSearchParams();
  const shipInsureIdParam = searchParams.get("ship_insure_id");

  // fetch claims on component mount; we check to see if
  // we have initially fetched an order to prevent re-rendering
  // and looping; no order with parameter and haven't fetched
  // indicates we need to fetch an order to prefill the step
  useEffect(() => {
    if (!hasFetchedOrder && shipInsureIdParam) {
      setIsAppLoading(true);
      prepopulateOrder(shipInsureIdParam);
    }

    async function prepopulateOrder(shipInsureId) {
      try {
        const response = await getOrderLookup({ shipInsureId });
        onOrderLookupSuccess(response.data);
      } catch (error) {
        // sliently fail
      } finally {
        setHasFetchedOrder(true);
        setIsAppLoading(false);
      }
    }
  }, [shipInsureIdParam, hasFetchedOrder]);
}

export function useScreenAnalytics(activeStep) {
  useEffect(() => {
    const stepMap = {
      0: "Order Lookup",
      1: "Claim Details",
      2: "Claim Review",
      3: "Submitted",
    };

    track(VIEW_SCREEN, {
      step: stepMap[activeStep],
    });
  }, [activeStep]);
}
