import React from "react";

export function Item(props) {
  return (
    <div
      className={`${props.hideDetails ? "ml-2.5 inline-block" : "mb-4 flex"}`}
    >
      <div className="relative inline-block">
        <div
          className="h-16 w-16 rounded border border-border-500 bg-white bg-cover bg-center"
          style={{
            backgroundImage: `url(${props.imageUrl})`,
          }}
        />
        {props.quantity > 0 && (
          <span
            className={`font-secondary absolute right-[-8px] top-[-8px] inline-flex h-5 w-5 items-center justify-center rounded-full bg-[#333333] text-xs font-bold text-white`}
          >
            {props.quantity}
          </span>
        )}
      </div>
      {!props.hideDetails && (
        <div className="font-secondary ml-5">
          <p className="text-gray-800">{props.name}</p>
          <p className="text-xs text-gray-600">{props.price}</p>
        </div>
      )}
    </div>
  );
}
