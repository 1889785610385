import { useState, useEffect } from "react";
import { Alert, Box, Button, Modal, Snackbar, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PlaidLink } from "react-plaid-link";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useSearchParams } from "react-router-dom";
import { GradientBox } from "./shared/GradientBox";
import { Menu } from "./shared/Menu";
import {
  CreditCard,
  Bank,
  ShipInsure,
  Plaid,
  Stripe,
} from "./shared/billingSvgs";
import { baseTheme } from "../../themes/shipinsure";
import { StripeProvider } from "../../components/Stripe";
import { createPlaidPayment, createPlaidToken } from "../../api/payments";
import { useOnboarding } from "./OnboardingProvider";

export function Billing() {
  const [plaidToken, setPlaidToken] = useState();
  const [plaidIsLoading, setPlaidIsLoading] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { merchant } = useOnboarding();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!merchant?.merchant_id) return;
    const fetchToken = async () => {
      try {
        const response = await createPlaidToken();

        setPlaidToken(response.data.token);
      } catch (error) {
        setPaymentError("There was a problem getting payment info");
      }
    };

    fetchToken();
  }, [merchant]);

  useEffect(() => {
    if (searchParams.get("payment") === "success") {
      setIsPaymentSuccess(true);
    }
  }, [searchParams]);

  const handleModalOpen = () => setModalIsOpen(true);
  const handleModalClose = () => setModalIsOpen(false);

  const onPlaidSuccess = async (token, data) => {
    try {
      setPlaidIsLoading(true);
      await createPlaidPayment({
        public_token: token,
        account_id: data.accounts[0].id,
        customer_id: merchant?.stripe_customer_id,
      });

      setIsPaymentSuccess(true);
    } catch (error) {
      setPaymentError("There was a payment error");
    } finally {
      setPlaidIsLoading(false);
    }
  };

  const renderPlaidButton = () => {
    return (
      <LoadingButton
        loading={plaidIsLoading}
        component="span"
        variant="contained"
        disabled={!plaidToken}
      >
        Transfer payment by bank account
      </LoadingButton>
    );
  };

  return (
    <GradientBox>
      <Box display="flex" padding="14px" height="100%">
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Menu step={4} />
        </Box>
        <Box
          bgcolor="#fff"
          width="100%"
          borderRadius="24px"
          sx={{ padding: { xs: "30px", md: "40px 69px" } }}
          display="flex"
          flexDirection="column"
        >
          <Typography variant="h3" color={baseTheme.colors.textLight}>
            We'll need your payment details. Here's why:
          </Typography>
          <Typography variant="p" mb="35px">
            To process claims, ShipInsure either issues direct refunds to
            customers or facilitates repurchases through your store. Since our
            widget gathers premiums from customers during checkout, ShipInsure
            requires your billing information to collect these premiums monthly.
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="25px"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Box maxWidth="200px">
              <Box display="flex" alignItems="center">
                <IconBox
                  sx={{
                    "& svg": {
                      width: "38px",
                    },
                    backgroundImage:
                      "linear-gradient(to right top, #beeaad, #b4e1a8, #abd8a3, #a2d09d, #99c798, #99c798, #98c698, #98c698, #a0ce9d, #a9d6a3, #b2dea8, #bbe6ad)",
                  }}
                >
                  <CreditCard />
                </IconBox>
                <Box marginLeft="auto">
                  <ArrowForwardIosRoundedIcon
                    fontSize="small"
                    sx={{ color: baseTheme.colors.primary }}
                  />
                </Box>
              </Box>
              <IconTitle>Step 1 -</IconTitle>
              <IconText>
                You collect your customer's payment and customer-paid package
                protection fee.
              </IconText>
            </Box>
            <Box maxWidth="200px">
              <Box display="flex" alignItems="center">
                <IconBox
                  sx={{
                    "& svg": {
                      width: "41px",
                    },
                    backgroundImage:
                      "linear-gradient(to left top, #beeaad, #b4e1a8, #abd8a3, #a2d09d, #99c798, #99c798, #98c698, #98c698, #a0ce9d, #a9d6a3, #b2dea8, #bbe6ad)",
                  }}
                >
                  <Bank />
                </IconBox>
                <Box marginLeft="auto">
                  <ArrowForwardIosRoundedIcon
                    fontSize="small"
                    sx={{ color: baseTheme.colors.primary }}
                  />
                </Box>
              </Box>
              <IconTitle>Step 2 -</IconTitle>
              <IconText>
                Both payments go to your company's bank account.
              </IconText>
            </Box>
            <Box maxWidth="200px">
              <IconBox
                sx={{
                  "& svg": {
                    width: "47px",
                  },
                  backgroundImage:
                    "linear-gradient(to right bottom, #beeaad, #b4e1a8, #abd8a3, #a2d09d, #99c798, #99c798, #98c698, #98c698, #a0ce9d, #a9d6a3, #b2dea8, #bbe6ad)",
                }}
              >
                <ShipInsure />
              </IconBox>
              <IconTitle>Step 3 -</IconTitle>
              <IconText>
                Your company transfers the customer-paid package protection fee
                back to us. This auto payment occurs weekly
              </IconText>
            </Box>
          </Box>
          {!isPaymentSuccess && !merchant?.stripe_payment_method_id ? (
            <>
              <Box
                display="flex"
                justifyContent="space-around"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "center", md: "initial" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Plaid height="72px" />
                  {plaidToken ? (
                    <PlaidLink
                      token={plaidToken}
                      onSuccess={onPlaidSuccess}
                      onCancel={() => setPlaidIsLoading(false)}
                      onExit={() => setPlaidIsLoading(false)}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        padding: 0,
                      }}
                    >
                      {renderPlaidButton()}
                    </PlaidLink>
                  ) : (
                    <>{renderPlaidButton()}</>
                  )}
                  <Typography
                    marginTop="10px"
                    fontSize="11px"
                    fontStyle="italic"
                    fontWeight={600}
                    textAlign="center"
                    color={baseTheme.colors.primary}
                  >
                    Best for customers!
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Stripe style={{ height: "72px", width: "100px" }} />
                  <Button variant="contained" onClick={handleModalOpen}>
                    Transfer payment by credit card
                  </Button>
                  <Modal
                    disableAutoFocus
                    open={modalIsOpen}
                    onClose={handleModalClose}
                  >
                    <Box
                      sx={{
                        minHeight: "400px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        borderRadius: "12px",
                        boxShadow: 24,
                        p: 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <StripeProvider
                        onCancel={handleModalClose}
                        merchantId={merchant?.merchant_id}
                        onError={() =>
                          setPaymentError("There was a payment error")
                        }
                      />
                    </Box>
                  </Modal>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                width="50%"
                position="relative"
                sx={{ margin: { xs: "50px auto", md: "auto auto 0" } }}
              >
                <Button href="https://merchants.shipinsure.io/">
                  Skip for now
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box textAlign="center">
                <Typography
                  fontSize="28px"
                  fontWeight={700}
                  fontFamily="Montserrat, sans-serif"
                  color={baseTheme.colors.primary}
                >
                  Congrats!
                </Typography>
                <Typography
                  fontFamily="Montserrat, sans-serif"
                  fontSize="24px"
                  fontWeight={400}
                >
                  Your billing has successfully been setup!
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                width="50%"
                position="relative"
                sx={{ margin: { xs: "50px auto", md: "auto auto 0" } }}
              >
                <Button
                  variant="contained"
                  href="https://merchants.shipinsure.io/"
                >
                  Next
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Snackbar
        open={!!paymentError}
        autoHideDuration={3000}
        onClose={(_, reason) => {
          if (reason === "clickaway") return;
          setPaymentError();
        }}
      >
        <Alert
          onClose={() => setPaymentError()}
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
        >
          {paymentError}
        </Alert>
      </Snackbar>
    </GradientBox>
  );
}

function IconTitle(props) {
  return (
    <Typography fontSize="12px" fontWeight={700} mb="8px" color="#444056">
      {props.children}
    </Typography>
  );
}

function IconText(props) {
  return (
    <Typography color="#82808E" fontSize="11px" fontWeight={700}>
      {props.children}
    </Typography>
  );
}

function IconBox(props) {
  return (
    <Box
      marginBottom="20px"
      width="100px"
      height="100px"
      borderRadius="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {props.children}
    </Box>
  );
}
