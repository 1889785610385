import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { createTranslator } from "next-intl";
import { Controller } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import ImageDropUpload from "../../../shared/ImageDropUpload";
import { useClaims } from "../ClaimsProvider";
import { Overview } from "../components/Overview";
import { ProductTitle } from "./components/ProductTitle";
import { getIssueOptions, getResolutionOptions } from "../components/options";
import content from "../../../content/claims.json";
import { baseTheme } from "../../../themes/shipinsure";

export function ResolveClaim() {
  const { claimsForm, order, setActiveStep, locale } = useClaims();
  const {
    setValue,
    getValues,
    register,
    reset,
    isSubmitting,
    control,
    watch,
    handleSubmit,
    formState: {
      isDirty,
      errors: { items: itemsErrors },
    },
  } = claimsForm;

  const t = createTranslator({
    messages: content[locale],
  });

  return (
    <>
      <Overview />
      <form
        onSubmit={handleSubmit((values) => {
          // submit to review step only if items have a reason
          if (values.items?.filter((v) => !!v.reason).length) {
            setActiveStep(2);
          }
        })}
      >
        <Box mt="32px">
          {order.line_items.map((item, index) => {
            const currentIssueType = watch(`items.${index}.issueType`);
            const currentReason = watch(`items.${index}.reason`);
            const issueOptions = getIssueOptions(
              currentIssueType,
              order.merchant_issues
            );

            const resolutionOptions = getResolutionOptions(
              issueOptions,
              currentReason,
              order.store_credits_enabled
            );
            const label =
              currentIssueType === "return"
                ? "What are you returning this item?*"
                : `${t("resolve.form.issue")}*`;

            return (
              <Box
                mb="16px"
                p="24px"
                bgcolor="#F4F4F9"
                borderRadius="8px"
                key={index}
              >
                <Grid container>
                  <ProductTitle item={item} title={t("resolve.variant")} />
                  <Grid
                    item
                    xs={12}
                    md={6}
                    textAlign="right"
                    mt={{ xs: "15px", md: "0px" }}
                  >
                    {order.allow_returns && (
                      <Box>
                        <Button
                          sx={{ width: { xs: "100%", md: "175px" } }}
                          size="small"
                          variant={
                            currentIssueType === "return"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            setValue(
                              `items.${index}.issueType`,
                              currentIssueType === "return" ? "" : "return"
                            );
                            setValue(`items.${index}.itemId`, item.id);
                            setValue(`items.${index}.reason`, "");
                            setValue(`items.${index}.details`, "");
                            setValue(`items.${index}.resolution`, "");
                            setValue(`items.${index}.images`, []);
                          }}
                          disabled={!order.shipping_protection_paid}
                        >
                          Return this item
                        </Button>
                      </Box>
                    )}
                    <Box mt="10px">
                      <Button
                        sx={{
                          pl: 2,
                          pr: 2,
                          maxWidth: "300px",
                        }}
                        size="small"
                        variant={
                          currentIssueType === "insurance"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          setValue(
                            `items.${index}.issueType`,
                            currentIssueType === "insurance" ? "" : "insurance"
                          );
                          setValue(`items.${index}.itemId`, item.id);
                          setValue(`items.${index}.reason`, "");
                          setValue(`items.${index}.details`, "");
                          setValue(`items.${index}.resolution`, "");
                          setValue(`items.${index}.images`, []);
                        }}
                        disabled={!order.shipping_protection_paid}
                      >
                        {t("resolve.form.toggle")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  {currentIssueType && (
                    <Box mt="30px">
                      <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            name={`items.${index}.reason`}
                            defaultValue=""
                            control={control}
                            rules={{
                              validate: (value) =>
                                currentIssueType && !value
                                  ? t("resolve.required")
                                  : true,
                            }}
                            render={({ field }) => (
                              <FormControl fullWidth>
                                <InputLabel>{label}</InputLabel>
                                <Select
                                  label={label}
                                  value=""
                                  {...field}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    // reset following fields if user chooses
                                    // to change their reason
                                    setValue(`items.${index}.images`, []);
                                    setValue(`items.${index}.details`, "");
                                    setValue(`items.${index}.resolution`, "");
                                  }}
                                  error={getFieldError(
                                    itemsErrors,
                                    index,
                                    "reason"
                                  )}
                                >
                                  {issueOptions.map((issue, i) => (
                                    <MenuItem key={i} value={issue.issue_name}>
                                      {issue.issue_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {getErrorMessage(itemsErrors, index, "reason")}
                              </FormControl>
                            )}
                          />
                        </Grid>
                        {currentReason === "Damaged" &&
                          currentIssueType === "insurance" && (
                            <Grid item xs={12}>
                              <ImageDropUpload
                                images={
                                  getValues(`items.${index}.images`) ?? []
                                }
                                onImageChange={(images) => {
                                  setValue(`items.${index}.images`, images, {
                                    shouldValidate: images.length
                                      ? true
                                      : false,
                                  });
                                }}
                                text={t.rich("resolve.upload", {
                                  text: (chunk) => (
                                    <Typography>{chunk}</Typography>
                                  ),
                                  cta: (chunk) => (
                                    <Typography
                                      component="span"
                                      color={baseTheme.colors.primary}
                                    >
                                      {chunk}
                                    </Typography>
                                  ),
                                })}
                              />
                              <input
                                type="hidden"
                                {...register(`items.${index}.images`, {
                                  validate: (value) => {
                                    return value.length === 0
                                      ? t("resolve.required")
                                      : true;
                                  },
                                })}
                              />
                              {getErrorMessage(itemsErrors, index, "images")}
                            </Grid>
                          )}
                        <Grid item xs={12}>
                          <Controller
                            name={`items.${index}.details`}
                            control={control}
                            defaultValue=""
                            rules={{
                              validate: (value) =>
                                currentIssueType && !value
                                  ? t("resolve.required")
                                  : true,
                            }}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                helperText={
                                  itemsErrors?.[index]?.details?.message
                                }
                                error={getFieldError(
                                  itemsErrors,
                                  index,
                                  "details"
                                )}
                                label={`${t("resolve.form.details")}*`}
                                placeholder={t("resolve.form.details")}
                                multiline
                                rows={4}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        {currentReason && (
                          <Grid item xs={12}>
                            <Controller
                              name={`items.${index}.resolution`}
                              control={control}
                              defaultValue=""
                              rules={{
                                validate: (value) =>
                                  currentIssueType && !value
                                    ? t("resolve.required")
                                    : true,
                              }}
                              render={({ field }) => (
                                <FormControl fullWidth>
                                  <InputLabel>
                                    {t("resolve.form.resolution")}*
                                  </InputLabel>
                                  <Select
                                    label={t("resolve.form.resolution")}
                                    value=""
                                    {...field}
                                    error={getFieldError(
                                      itemsErrors,
                                      index,
                                      "resolution"
                                    )}
                                  >
                                    {resolutionOptions.map((option, i) => (
                                      <MenuItem key={i} value={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {getErrorMessage(
                                    itemsErrors,
                                    index,
                                    "resolution"
                                  )}
                                </FormControl>
                              )}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box display="flex" mt="36px">
          <Box flex="0 0 20%" mr="20px">
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                reset();
                setActiveStep(0);
              }}
            >
              {t("resolve.form.back")}
            </Button>
          </Box>
          <Box flex="1">
            <LoadingButton
              disabled={!isDirty || !order.shipping_protection_paid}
              loading={isSubmitting}
              variant="contained"
              fullWidth
              type="submit"
            >
              {t("resolve.form.submit")}
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </>
  );
}

// helper to get field error validation prop
function getFieldError(items, index, key) {
  return items?.[index]?.[key] ? true : false;
}

// helper to get helper text for field message
function getErrorMessage(items, index, key) {
  return (
    items?.[index]?.[key] && (
      <FormHelperText error>{items[index][key].message}</FormHelperText>
    )
  );
}
