import React from "react";
import { cn } from "../../../utils/tailwind";

export function Card({ children, className }) {
  return (
    <div
      className={cn(
        "aspect-square w-full flex-1 rounded-md border border-border-600 px-8 py-6 shadow-sm",
        className,
      )}
    >
      {children}
    </div>
  );
}
