import React from "react";
import { useState } from "react";
import { Item } from "./Item";
import { ItemsOverview } from "./ItemsOverview";
import { LineItem } from "../../types";

export type OverviewProps = {
  items: Array<{ name: string; price: string; quantity: number }>;
  orderStatusUrl: string;
  orderNumber: string;
};

export function PackageOverview(props: OverviewProps) {
  return (
    <div>
      <div className="md:hidden">
        <MobileDrawer {...props} />
      </div>
      <div className="hidden md:block">
        <ItemsOverview {...props} />
      </div>
    </div>
  );
}

function MobileDrawer(props: OverviewProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex justify-between">
        <div className="block max-w-[200px]">
          {props.items.map((item, i) => (
            <Item {...item} hideDetails key={i} />
          ))}
        </div>
        <button
          className="flex items-center text-gray-600"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          ({props.items.length} item{props.items.length > 1 ? "s" : ""}){" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            style={{ marginLeft: "10px" }}
          >
            <path
              d="M9.81796 8L2.02848 16L0.210938 14.1333L6.18287 8L0.210938 1.86667L2.02848 0L9.81796 8Z"
              fill="#949494"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="fixed left-0 top-0 z-50 h-full w-full bg-black bg-opacity-25">
          <div className="fixed bottom-0 w-full rounded-tl-xl rounded-tr-xl bg-white px-8 pb-12 pt-14">
            <button
              className="absolute right-[20px] top-[20px]"
              onClick={() => setIsOpen(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_2968_3347"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2968_3347)">
                  <path
                    d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                    fill="#949494"
                  />
                </g>
              </svg>
            </button>
            <ItemsOverview {...props} />
          </div>
        </div>
      )}
    </>
  );
}
