import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.css";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import * as serviceWorker from "./serviceWorker";
import "./tailwind.css";
import { TenantThemeProvider } from "./themes/TenantThemeProvider";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <TenantThemeProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </TenantThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
