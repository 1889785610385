import React, { useState } from "react";
import defaultHeroImage from "../../assets/images/fallback-tracking-hero.png";
import { ShipInsureLogo } from "../../svgs/ShipInsureLogo";
import { useTenantTheme } from "../../themes/TenantThemeProvider";
import { Card } from "./components/Card";
import { Container } from "./components/Container";
import { EstimatedDeliveryCard } from "./components/EstimatedDeliveryCard";
import { PackageOverview } from "./components/PackageOverview/PackageOverview";
import { PackageSupport } from "./components/PackageSupport";
import { cn } from "../../utils/tailwind";
import { Order, Shipment } from "./types";

export function NewTracking(props: { order: Order }) {
  const { siTheme } = useTenantTheme();
  const [shipment, setShipments] = useState<null | Shipment>(
    props.order.shipments.length ? props.order.shipments[0] : null,
  );
  const heroImageUrl = siTheme.trackingPageImageUrl || defaultHeroImage;
  const { primaryFont, secondaryFont } = siTheme;
  const currentFulfillment = props.order.fulfillments?.find(
    (d) => d.tracking_number && d.tracking_number === shipment?.tracking_number,
  );

  console.log(props.order, "Order");

  return (
    <div className="font-primary pb-16" style={{ fontFamily: primaryFont }}>
      {/* Header */}
      <Container className="">
        <header className="flex h-[90px] items-center justify-center sm:justify-between">
          {siTheme.horizontalLogoUrl ? (
            <img
              src={siTheme.horizontalLogoUrl}
              style={{ maxHeight: "40px" }}
              alt="Logo"
            />
          ) : (
            <>
              <ShipInsureLogo color="#6675FF" />
              <span className="sr-only">ShipInsure logo</span>
            </>
          )}
          <button className="hidden rounded-full border bg-white px-4 py-2 text-sm sm:block">
            Contact Us
          </button>
        </header>
      </Container>

      {/* Hero and Delivery Details Card */}
      <div className="grid grid-cols-12 grid-rows-8">
        {/* Hero */}
        <div
          className="col-span-full row-span-6 row-start-2 h-64 w-full bg-cover bg-no-repeat sm:h-[512px]"
          style={{
            backgroundImage: `url(${heroImageUrl})`,
            backgroundPosition: siTheme.trackingPageImageUrl
              ? siTheme.trackingPageImagePosition
              : "center",
          }}
        />
        <div className="z-10 col-span-full row-start-2 flex items-center justify-center px-4">
          {props.order.shipments.length > 1 &&
            props.order.shipments.map((shipmentPackage, i) => {
              const isActive = shipmentPackage.id === shipment?.id;

              return (
                <button
                  className={`${cn(isActive ? "bg-primary border-primary text-white" : "border-[#E2E8F0] bg-white")} hover:bg-primary hover:border-primary mx-1 rounded-full border px-8 py-[10px] text-sm font-semibold hover:text-white`}
                  onClick={() => setShipments(shipmentPackage)}
                  key={`${shipmentPackage.id}`}
                >
                  Package {i + 1}
                </button>
              );
            })}
        </div>

        {/* Delivery Details Card */}
        <div className="z-10 col-span-6 col-start-4 row-span-6 row-start-3 grid h-full place-content-center place-items-center gap-4 self-start rounded-lg bg-white p-6 shadow-lg lg:col-span-4 lg:col-start-5">
          {shipment && <EstimatedDeliveryCard shipment={shipment} />}
        </div>
      </div>
      <div className="mt-20 space-y-14">
        {/* Map, Order Details and Contact Card */}
        <Container className="flex flex-col gap-6 md:flex-row">
          {/* Map */}
          <Card className="p-0">Map</Card>
          {/* Order Details */}
          <Card className="">
            <PackageOverview
              orderStatusUrl={props.order.order_status_url}
              orderNumber="#4687"
              items={
                currentFulfillment?.line_items.map((d) => ({
                  imageUrl:
                    "https://www.rockstaroriginal.com/cdn/shop/files/03_6b8a4826-ef0c-48d2-b753-1ebf472e8c76.webp?v=1728071579&width=900",
                  name: d.title,
                  price: `$${d.price}`,
                  quantity: d.quantity,
                })) ?? []
              }
            />
          </Card>
          {/* Contact Card */}
          <Card className="">
            <PackageSupport />
          </Card>
        </Container>
        <Container className="flex flex-col gap-3">
          <h2 className="text-lg font-bold">You may also like</h2>
          <div className="flex flex-col gap-6 sm:flex-row">
            <Card className="">Suggested Products 1</Card>
            <Card className="">Suggested Products 2</Card>
            <Card className="">Suggested Products 3</Card>
            <Card className="">Suggested Products 4</Card>
          </div>
        </Container>
      </div>
    </div>
  );
}
