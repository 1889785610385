import { Alert, Button, Grid, Typography } from "@mui/material";
import { createTranslator } from "next-intl";
import { format } from "date-fns";
import { useClaims } from "../ClaimsProvider";
import content from "../../../content/claims.json";

export function Overview() {
  const { order, isSelectAll, onSelectAllToggle, locale } = useClaims();
  const t = createTranslator({
    messages: content[locale],
  });

  return (
    <>
      <Typography variant="h3">{t("resolve.title")}</Typography>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Typography paragraph mb="5px">
            {t("resolve.order.number")}{" "}
            <Typography display="inline" fontWeight={700} component="span">
              {order.order_number}
            </Typography>
          </Typography>
          {order.created_at && (
            <Typography paragraph mb="5px">
              {t("resolve.order.date")}{" "}
              <Typography display="inline" fontWeight={600} component="span">
                {format(new Date(order.created_at), "MMMM d, yyyy")}
              </Typography>
            </Typography>
          )}
          <Typography paragraph mb="5px">
            {t("resolve.order.store")}{" "}
            <Typography display="inline" fontWeight={600} component="span">
              {order.store_name}
            </Typography>
          </Typography>
          <Typography paragraph mb="5px">
            {t("resolve.order.items")}{" "}
            <Typography display="inline" fontWeight={600} component="span">
              {order.line_items.length}
            </Typography>
          </Typography>
        </Grid>
        {order.shipping_protection_paid ? (
          <Grid item xs={12}>
            <Typography paragraph sx={{ mt: 2, fontSize: "12px", mb: 0 }}>
              {t("resolve.select")}
            </Typography>
            {t.rich("resolve.toggle", {
              button: (chunk) => (
                <Button
                  fullWidth
                  variant={isSelectAll ? "contained" : "outlined"}
                  sx={{ fontSize: "12px", mt: 2 }}
                  onClick={onSelectAllToggle}
                >
                  {chunk}
                </Button>
              ),
            })}
          </Grid>
        ) : (
          <Grid item xs={12}>
            {t.rich("resolve.order.uncovered", {
              text: (chunk) => (
                <Alert
                  fullWidth
                  variant={"filled"}
                  color="error"
                  severity="error"
                  sx={{ fontSize: "14px", mt: 2 }}
                >
                  {chunk}
                </Alert>
              ),
            })}
          </Grid>
        )}
      </Grid>
    </>
  );
}
