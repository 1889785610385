import { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { getOrder } from "../../api/order";
import { useTenantTheme } from "../../themes/TenantThemeProvider";
import { NewTracking } from "./NewTracking";
import { Tracking } from "./Tracking";
import { Loader } from "../../shared/Loader";

export function TrackingRenderer() {
  // look for search params for preview_v2=true
  const [searchParams] = useSearchParams();
  const previewV2 = searchParams.get("preview_v2");
  const isV2 = previewV2 === "true";
  const { shipInsureId } = useParams();
  const { isThemeLoading } = useTenantTheme();

  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(isV2);

  useEffect(() => {
    if (!isV2) return;

    const lookupOrder = async () => {
      try {
        const response = await getOrder(shipInsureId, {
          include_carrier_detail: 1,
        });

        setOrder(response.data);
      } catch (error) {
        console.log("Error loading order");
      } finally {
        setIsLoading(false);
      }
    };

    lookupOrder();
  }, [isV2]);

  if ((isThemeLoading || isLoading) && isV2) return <Loader />;
  if (!order && isV2) return <>We couldn't find your order</>;

  if (isV2) {
    return <NewTracking order={order} />;
  }

  return <Tracking />;
}
