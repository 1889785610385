import { Button, Typography, Divider, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { createTranslator } from "next-intl";
import { MainLayout } from "../../layouts/MainLayout";
import { Card } from "../../shared/Card";
import { useTenantTheme } from "../../themes/TenantThemeProvider";
import content from "../../content/site.json";

export function Home() {
  const { whitelabel, locale } = useTenantTheme();
  const storeName = whitelabel?.custom_attributes?.store_name ?? "ShipInsure";
  const t = createTranslator({
    messages: content[locale],
  });

  return (
    <MainLayout helpText={t("helpText")}>
      <Card>
        {t.rich("body", {
          storeName,
          title: (chunk) => <Typography variant="h3">{chunk}</Typography>,
          text: (chunk) => <Typography paragraph>{chunk}</Typography>,
          claimButton: (chunk) => (
            <Button
              variant="contained"
              fullWidth
              to="/claims"
              component={Link}
              sx={{ my: "30px" }}
            >
              {chunk}
            </Button>
          ),
          siteButton: (chunk) => (
            <Button
              variant="outlined"
              fullWidth
              href="https://shipinsure.io"
              target="_blank"
            >
              {chunk}
            </Button>
          ),
          hr: () => <Divider sx={{ my: 6 }} />,
        })}
      </Card>
    </MainLayout>
  );
}
